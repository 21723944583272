import { ReactElement, useState, useEffect } from 'react'
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Grid,
  VStack,
  FormLabel,
  FormErrorMessage,
  Text,
  Center,
  useToast,
  Link,
} from '@chakra-ui/react'
import { TiArrowSortedDown } from 'react-icons/ti'

import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import { FormControl } from '@/components/atoms/FormControl'
import Textarea from '@/components/atoms/Textarea'
import TextInput from '@/components/atoms/TextInput'
import NumberInput from '@/components/atoms/NumberInput'
import Select from '@/components/atoms/Select'

import { logEvent } from '@/lib/stats'

import { COURSE_OPTIONS } from './constants'
import { LandingPageContent } from '../../constants'
import { ContactFormInput, SectionProps } from '../../types'
import { sendEmail, sendSlack } from '../../services'

const PROMOTIONS = LandingPageContent.promotions

type Props = {} & SectionProps

export const Contact = (props: Props): ReactElement => {
  const { id } = props

  return (
    <Box
      as="section"
      id={id}
      sx={{
        bgColor: 'primary.800',
        bgImage: {
          base: 'url("/images/Landing/Contact/contact-bg-mobile.jpg")',
          md: 'url("/images/Landing/Contact/contact-bg-desktop.svg")',
        },
        bgPosition: 'center',
        bgRepeat: 'no-repeat',
        bgSize: { base: 'cover', md: 'unset' },
        py: {
          base: '96px',
          sm: '200px',
        },
      }}
    >
      <Box id={id} layerStyle="landingContainer">
        <VStack spacing="4" sx={{ textAlign: 'center' }}>
          <Text
            as="h1"
            textStyle="h1"
            sx={{
              color: 'white',
              fontSize: { base: '2xl', sm: '4xl', md: '5xl' },
            }}
          >
            สนใจซื้อคอร์ส/สอบถามเพิ่มเติม
          </Text>
          <Text
            as="h2"
            sx={{ color: '#30ccdb', fontSize: { base: 'xl', sm: '3xl' } }}
          >
            เริ่มต้น 600 THB / คน
          </Text>
          <Text
            sx={{
              fontWeight: 300,
              color: 'white',
              fontSize: { base: 'md', sm: '2xl' },
            }}
          >
            กรอกข้อมูลเพื่อซื้อคอร์สหรือสอบถามเพิ่มเติม
            เราจะดำเนินการติดต่อกลับภายใน 24 ชั่วโมง
          </Text>
        </VStack>
        <ContactForm />
      </Box>
    </Box>
  )
}

const ContactForm = (): ReactElement => {
  const [loading, setLoading] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)
  const toast = useToast()

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
    reset,
    setValue,
    control,
  } = useForm<ContactFormInput>({
    defaultValues: {
      name: '',
      company: '',
      email: '',
      tel: '',
      licenses: '',
      details: '',
      promotion: '-',
      interestedCourse: [],
    },
    resolver: zodResolver(schema),
  })

  const selectedPromotion = watch('promotion')
  const licensesCount = watch('licenses')
  const promotions = PROMOTIONS.filter(
    (promotion) => parseInt(licensesCount) >= promotion.minPurchase,
  )

  useEffect(() => {
    const index = promotions.findIndex((promotion) => {
      return promotion.value === selectedPromotion
    })

    if (index < 0) {
      setValue('promotion', '-')
    }
  }, [licensesCount])

  const onSubmit = async (values: ContactFormInput) => {
    if (isSubmitting) return

    setLoading(true)

    try {
      await sendEmail(values)
      await sendSlack(values)

      logEvent({
        ga: {
          category: 'Contact Us',
          action: 'Contact Us Form Success',
        },
        fb: {
          event: 'Lead',
        },
      })
      toast({
        description:
          'ได้รับข้อมูลเรียบร้อย เราจะรีบทำการติดต่อกลับโดยเร็วที่สุด',
        status: 'success',
        position: 'top',
        variant: 'left-accent',
      })
      reset()
    } catch (err) {
      toast({
        description: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
        status: 'error',
        position: 'top',
        variant: 'left-accent',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        spacing="4"
        sx={{
          mb: 6,
          mt: 10,
          gridTemplateColumns: {
            base: '1fr',
            sm: '1fr 1fr',
          },
          gridGap: 5,
        }}
      >
        <FormControl id="name" errorMsg={errors.name?.message}>
          <TextInput
            placeholder="ชื่อผู้ติดต่อ"
            name="name"
            register={register}
          />
        </FormControl>
        <FormControl id="company" errorMsg={errors.company?.message}>
          <TextInput
            placeholder="ชื่อบริษัท/องค์กร"
            name="company"
            register={register}
          />
        </FormControl>
        <FormControl id="email" errorMsg={errors.email?.message}>
          <TextInput
            placeholder="อีเมล"
            type="email"
            name="email"
            register={register}
          />
        </FormControl>
        <FormControl id="tel" errorMsg={errors.tel?.message}>
          <TextInput
            placeholder="เบอร์โทร"
            type="tel"
            name="tel"
            register={register}
          />
        </FormControl>
        <FormControl id="licenses" errorMsg={errors.licenses?.message}>
          <NumberInput
            inputProps={{
              isRequired: true,
              placeholder: 'จำนวน license ที่ต้องการซื้อ',
              type: 'number',
              name: 'licenses',
              register,
            }}
            numberInputProps={{
              min: 0,
              onChange: (value) => setValue('licenses', value),
            }}
            value={licensesCount}
          />
        </FormControl>
        <FormControl id="promotions" isRequired>
          <Select
            placeholder="โปรโมชั่นที่สนใจ"
            name="promotion"
            icon={<TiArrowSortedDown color="blue.400" />}
            register={register}
            color={selectedPromotion ? 'blue.400' : 'gray.400'}
            customColor={{
              bgColor: ['#ffffffff', '#ffffff00'],
              color: [
                'primary.800',
                selectedPromotion !== '-' ? 'blue.400' : 'gray.400',
              ],
              placeholderColor: ['gray.400', 'white'],
              borderColor: ['white', 'white'],
            }}
            defaultValue="-"
          >
            <option value="-" disabled>
              เลือกรับโปรโมชั่น
            </option>
            {promotions.length > 0 && (
              <option value="ไม่ต้องการรับโปรโมชั่น">
                ไม่ต้องการรับโปรโมชั่น
              </option>
            )}
            {PROMOTIONS.map((promotion) => (
              <option
                key={promotion.value}
                value={promotion.value}
                disabled={
                  parseInt(licensesCount || '0') < promotion.minPurchase
                }
              >
                {promotion.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <Controller
          name="interestedCourse"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <FormControl
                id="interested-products"
                sx={{ gridColumn: { lg: '1 / 3' }, color: 'white' }}
              >
                <FormLabel>
                  คอร์สเรียนที่สนใจ (สามารถเลือกได้มากกว่า 1 ข้อ)
                </FormLabel>
                <CheckboxGroup onChange={onChange} value={value}>
                  <Grid
                    sx={{
                      gridTemplateColumns: {
                        base: '1fr',
                        lg: 'repeat(2, 1fr)',
                      },
                      gridGap: '4px 24px',
                    }}
                  >
                    {COURSE_OPTIONS.map((option, index) => {
                      return (
                        <Checkbox
                          colorScheme="primary"
                          key={`${index}`}
                          value={option}
                          size="lg"
                          sx={{
                            alignItems: 'flex-start',
                            span: { fontSize: 'md' },
                          }}
                        >
                          {option}
                        </Checkbox>
                      )
                    })}
                  </Grid>
                </CheckboxGroup>
                <FormErrorMessage>
                  {errors?.interestedCourse?.message}
                </FormErrorMessage>
              </FormControl>
            )
          }}
        />
        <FormControl
          id="details"
          sx={{ gridColumn: { base: '1', sm: '1 / 3' } }}
        >
          <Textarea
            placeholder="รายละเอียดเพิ่มเติม"
            name="details"
            register={register}
            height="200px"
          />
        </FormControl>
        <FormControl id="termAndCondition" sx={{ color: 'white' }}>
          <Checkbox
            colorScheme="primary"
            size="lg"
            sx={{
              alignItems: 'flex-start',
              span: { fontSize: 'md' },
            }}
            isChecked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          >
            <FormLabel>
              ฉันยอมรับ<span> </span>
              <Link
                href="https://learnpdpa.com/terms-of-use"
                sx={{ color: '#30ccdb' }}
              >
                เงื่อนไขการให้บริการ
              </Link>
              <span> และ </span>
              <Link
                href="https://learnpdpa.com/privacy-policy"
                sx={{ color: '#30ccdb' }}
              >
                นโยบายความเป็นส่วนตัว
              </Link>
            </FormLabel>
          </Checkbox>
        </FormControl>
      </Grid>

      <Center>
        <Button
          type="submit"
          sx={{
            px: '100px',
            h: ['48px', '72px'],
            fontSize: ['16px', '24px'],
            mx: 'auto',
          }}
          size="lg"
          isLoading={loading}
          isDisabled={!termsAccepted}
        >
          ติดต่อเรา
        </Button>
      </Center>
    </form>
  )
}

const schema = z.object({
  name: z.string().nonempty({ message: 'จำเป็นต้องกรอก' }),
  company: z.string().nonempty({ message: 'จำเป็นต้องกรอก' }),
  email: z
    .string()
    .nonempty({ message: 'จำเป็นต้องกรอก' })
    .email({ message: 'อีเมลไม่ถูกต้อง' }),
  tel: z.string().regex(/^\d{10}$/, { message: 'เบอร์โทรไม่ถูกต้อง' }),
  licenses: z.string().nonempty({ message: 'จำเป็นต้องกรอก' }),
  details: z.string().optional(),
  interestedCourse: z.array(z.string()),
  promotion: z.string().optional(),
})
